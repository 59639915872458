/* General styles for the table container */
.tablediv {
    max-width: 1200px; /* Set a max width for the table */
    margin: 20px auto; /* Center the table on the page */
    padding: 20px;
    border: 1px solid #ccc; /* Border for the container */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

  
/* Styles for the filter section */
.filter-section {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow wrapping of items */
    justify-content: center; /* Center the filters */
    margin-bottom: 20px; /* Space below the filter section */
}
 
/* Label styles */
.label1 {
    margin: 10px; /* Margin around labels */
    font-weight: bold; /* Bold text for labels */
}
 
/* Dropdown styles */
.react-select-container {
    min-width: 200px; /* Minimum width for dropdowns */
}
 
/* Button styles */
.download-button {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none; /* Remove default border */
    padding: 10px 15px; /* Padding around the button */
    text-align: center; /* Center text */
    text-decoration: none; /* No underline */
    display: inline-block; /* Inline block for proper sizing */
    font-size: 16px; /* Font size */
    margin: 10px auto; /* Margin for spacing */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth background transition */
}
 
/* Button hover effect */
.download-button:hover {
    background-color: #45a049; /* Darker green on hover */
}
 
/* Table styles */
table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Collapse borders */
    margin-top: 20px; /* Space above the table */
}
 
/* Table header styles */
th {
    background-color: #f2f2f2; /* Light gray background for header */
    color: #333; /* Darker color for text */
    padding: 10px; /* Padding for header cells */
    text-align: left; /* Left align text */
}
 
/* Table cell styles */
td {
    padding: 10px; /* Padding for data cells */
    border-bottom: 1px solid #ddd; /* Bottom border for rows */
}
 
/* Table row hover effect */
tr:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}
