.results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.charts-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
}

.section-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: 10px;
}

.section-chart h3 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.section-details {
  text-align: center;
  margin-top: 10px;
}

.section-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.overall-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.overall-chart h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.custom-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    padding: 2px;
    font-size: 15px;
    color: #333;
    text-align: left;
}

.input-fields {
  margin: 20px 0; /* You can adjust this if needed */
  display: flex;
  flex-direction: column; /* Stack input pairs vertically */
  align-items: flex-start; /* Align items to the left */
  width: 100%;
}
@media (max-width: 768px) {
  .input-fields {
    margin: 20px -20px; /* You can adjust this if needed */
}
}
.input-pair {
  display: flex;
  align-items: center; /* Center vertically */
  margin-bottom: 10px; /* Space between each input pair */
  width: 100%;
}

.input-pair label {
  margin-right: 10px; /* Space between label and input */
  flex: 0 0 100px; /* Fixed width for labels */
}
@media (max-width: 768px) {
  .input-pair label {
      margin-right: 0px; /* Adjust margin for smaller screens */
      flex: 0 0 80px;    /* Adjust flex-basis if needed */
  }
}

.input-pair input {
  flex: 1; /* Allow input to take remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* CSS for the selected model */
.selected-model {
    margin-top: 20px;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
  }
  
  .selected-model h3 {
    margin: 0;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: inline-block;
    color: #0070c0; /* Color for the model name */
  }
  
  /* CSS for the submit button */
  .submit-button {
    text-align: center;
    margin-top: 30px;
  }
  
  .submit-button button {
    padding: 10px 20px;
    background-color: #0070c0;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button button:hover {
    background-color: #005b99;
  }
  
  
  @media print {
    #pdf-content {
      width: 210mm; /* A4 width */
      height: 297mm; /* A4 height */
      overflow: hidden; /* Prevent overflow */
      padding: 20mm; /* Add some padding */
      box-sizing: border-box; /* Ensure padding is included in size */
    }
  
    .overall-result, .charts-row, .section-chart {
      page-break-inside: avoid; /* Prevent breaking inside charts */
    }
  }
.higherScore {
  font-size: 50px;
  font-weight: bold;
  color: darkblue;
  margin: auto;
}
#higherScoreTitle {
  color: darkblue;
  font-weight: 100;
}
.overall-result {
  margin : 30px;
}

