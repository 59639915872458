.header {
    text-align: right; /* Center the content */
    padding: 20px; /* Add some padding */
  }
  
  .header-image {
    /* max-width: 100%;  */
    height: auto; /* Keep the aspect ratio */
    width: 100px;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height */
    background-color: rgba(255, 255, 255, 0.8); /* Optional background */
  }
  