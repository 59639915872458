.survey-container {
    font-family: 'Arial', sans-serif;
    max-width: 80%;
    margin: auto;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
}
.containerheader {
    justify-content: space-around;
    background-color: #007bff;
    padding: 1px;
    color: white;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
}

.custom-hr {
  height: 1px; /* Height of the line */
  background-color: #007bff; /* Color of the line */
  margin: 10px 0; /* Margin around the line */
  width: 100%;
}


.questioninstruction {
  justify-content: space-around;
  color:#007bff;
  font-weight: bold;
  font-size: 13px;
}
.title{
  color:#007bff;
}

.navbar {
  display: flex;
  justify-content: space-around; /* Center the buttons */
  /* gap: 10px; Space between buttons */
  /* margin-bottom: 20px; */
  padding: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .navbar {
    display: grid
  }
}


.navbar button {
  padding: 10px; /* Padding around the text */
  font-size: 16px; /* Font size */
  background-color: white; /* Background color */
  border: 2px solid rgba(128, 128, 255, 0.5); /* Light purple border */
  border-radius: 20px; /* Rounded corners */
  color: black; /* Text color */
  cursor: pointer; /* Pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

@media (max-width: 768px) {
  .navbar button {
    margin: 4px;
  }
}


.navbar button.active {
  border-color: rgba(128, 128, 255, 1); /* Solid purple border for active */
  background-color: rgba(128, 128, 255, 0.2); /* Light background color for active */
}

.navbar button:hover {
  border-color: rgba(128, 128, 255, 1); /* Change border color on hover */
  background-color: rgba(128, 128, 255, 0.1); /* Slight background change on hover */
}


.section {
    padding: 30px;
    border: 1px solid #007bff;
    border-radius: 0px 0px 20px 20px;
}

.question {
    margin-bottom: 40px;
    border: 1px rgba(128, 128, 255, 1) solid;
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.question-text {
    font-weight: bold;
    margin-bottom: 40px;
    /* text-align: left; */
    font-size: 18px;
    color: darkblue;
}

.options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between the options */
    font-size: 16px;
}

.option {
    display: inline-flex;
    align-items: center;
    width: calc(50% - 10px); /* Ensure two options per row with spacing */
    text-align: left;
    color: grey;
}
@media (max-width: 768px) {
  .option {
  display: flow;
  align-items: center;
  width: calc(100%); /* Ensure two options per row with spacing */
  text-align: left;
  color: grey;
  }
}

.option input[type="checkbox"] {
    margin-right: 10px;
}

.option.selected {
    background-color: #cce5ff;
    padding: 5px;
    border-radius: 5px;
}
@media (min-width: 600px) {
  .option.selected {
    margin-left: 8px;
  }
}

@media (max-width: 600px) {
  .option.selected {
    margin-top: 8px;
  }
}


.score-summary {
    font-size: 16px;
    padding-top: 10px;
}

.error {
    color: red;
    font-size: 0.875rem; /* Adjust font size as needed */
    margin: 12px auto 5px auto;
}

/* Survey.css */
.option-error {
    border: 1px solid red;
    padding: 20px;
    border-radius: 25px;
  }
  

.introduction {
    text-align: left; /* Align text to the left */
    padding: 10px; /* Optional: Add some padding for spacing */
  }
  


  /* styles.css */
 
.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background: #5ca2eb;
    color: white;
    margin-top: 15px;
}
    
.btn:hover {
  background-color: #0056b3;
}
   
.btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navbar .nav-button.error-section {
  border: 1px solid rgb(226, 73, 73); /* Light purple border */
  border-radius: 20px; /* Rounded corners */
}
.error {
  color: red;
  font-weight: bold;
}

.button-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out items */
  margin-top: 20px; /* Space above */
}

.button-center {
  flex: 1; /* Allow the button section to grow */
  display: flex; /* Flexbox for button centering */
  justify-content: center; /* Center the button */
}

.logo-button-container {
  margin-left: 15px; /* Space between button and logo */
}

.logo-button-container img {
  height: 65px; /* Adjust based on your logo size */
  width: auto; /* Maintain aspect ratio */
}

.tablediv{
  overflow: auto;
}

.tablediv {
  margin: 20px;
  font-family: Arial, sans-serif;
}

/* h1 {
  text-align: center;
  color: #333;
} */

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

th {
  background-color: #f4f4f4;
  color: #555;
  position: sticky;
  top: 0;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.error-message {
  color: red;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
}

