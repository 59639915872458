/* Overall container for the dashboard */
.dashboard-container {
  width: 100%;
  margin: 0 auto;
}

/* Styles for the top navbar */
.top-navbar {
  width: 100%;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.top-navbar h4 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

.filter-group {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

/* Set fixed width and height for dropdown filter boxes */
.filter-group div {
  flex-direction: column;
  width: 170px; /* Fixed width for dropdown boxes */
}

.filter-group label {
  font-weight: bold;
  margin-bottom: 5px;
}


.filter-group input {
  padding: 5px;
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-group button {
  height: 40px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-group button:hover {
  background-color: #0056b3;
}

/* Styles for the charts section */
.charts-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.charts-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.chart-container {
  width: 45%;
  padding: 10px;
}

/* Responsive design for tablets and smaller devices */
@media (max-width: 768px) {
  .filter-group {
    flex-direction: column;
  }

  .filter-group div {
    width: 100%; /* Full width for smaller devices */
  }

  .filter-group input {
    height: 40px; /* Maintain fixed height on smaller devices */
  }

  .charts-row {
    flex-direction: row;
    gap: 10px;
  }

  .chart-container {
    width: 100%;
  }
}

/* Responsive design for mobile */
@media (max-width: 576px) {
  .top-navbar {
    padding: 10px;
  }

  .filter-group button {
    width: 100%;
  }

  .filter-group input {
    font-size: 14px;
    height: 40px; /* Maintain fixed height on mobile */
  }

  .top-navbar h4 {
    font-size: 1.2em;
  }

  .charts-row {
    gap: 5px;
  }

  .chart-container {
    width: 100%;
  }
}

/* Additional CSS for Dashboard.js */
.section {
    padding: 20px;
}

.containerheader {
    text-align: center;
}

.respondent-count {
    margin: 20px 0;
    text-align: center;
}

.logo-button-container {
    text-align: end;
    margin-top: 20px;
}

.logo-button-container img {
    width: 100px; /* Adjust logo size as needed */
}

